<template class="mr-3">
  <div class="card">
    <div class="card-header card-header-flex align-items-center">
      <div class="d-flex flex-column justify-content-center mr-auto">
        <h5 class="mb-0">
          <strong>{{ $t("utilisateur.listeUtilisateur") }}</strong>
        </h5>
      </div>
      <div>
        <a-switch
          default-checked
          :checked-children="$t('utilisateur.active')"
          :un-checked-children="$t('utilisateur.nonActive')"
          @change="onChange"
        />
      </div>
    </div>
    <div class="card-body">
      <template>
        <div class="card-body">
          <a-button type="user-add" @click="showModal">
            <a-icon type="user-add" @click="showModal" />{{
              $t("action.ajouter")
            }}
          </a-button>
          <div>
            <a-modal
              v-model="visibleModal"
              @cancel="handleCancel"
              :title="$t('all.enterInformationBelow')"
              :width="750"
              :height="1200"
              :footer="false"
            >
              <a-form :form="form" class="container" @submit="addUtilisateur">
                <div class="row">
                  <div class="col-lg-6">
                    <a-form-item :label="$t('utilisateur.role')">
                      <a-select
                        show-search
                        :autoFocus="true"
                        option-filter-prop="children"
                        :filter-option="filterOption"
                        :placeholder="$t('utilisateur.role')"
                        v-decorator="[
                          'role',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.role'),
                              },
                            ],
                          },
                        ]"
                      >
                        <a-select-option
                          :value="item._id"
                          v-for="item in roleList"
                          :key="item._id"
                        >
                          {{ item.role }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <div class="col-lg-6">
                    <a-form-item :label="$t('profil.nomUser')">
                      <a-input
                        :placeholder="$t('profil.nomUser')"
                        v-decorator="[
                          'userName',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.username'),
                              },
                              {
                                validator: validateNoWhitespace,
                                message: $t('requis.noWhitespace'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <a-form-item :label="$t('profil.mdp')">
                      <a-input
                        :placeholder="$t('profil.mdp')"
                        type="password"
                        v-decorator="[
                          'password',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.mdp'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-lg-6">
                    <a-form-item :label="$t('utilisateur.confirmerMdp')">
                      <a-input
                        :placeholder="$t('utilisateur.confirmerMdp')"
                        type="password"
                        v-decorator="[
                          'confirmPassword',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.confirmation'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <a-form-item :label="$t('utilisateur.acces')">
                      <a-select
                        show-search
                        :autoFocus="true"
                        :allowClear="true"
                        mode="multiple"
                        option-filter-prop="children"
                        :filter-option="filterOption"
                        style="width: 100%"
                        :placeholder="$t('utilisateur.acces')"
                        v-decorator="[
                          'buildings',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.role'),
                              },
                            ],
                          },
                        ]"
                      >
                        <a-select-option
                          :value="item._id"
                          v-for="item in buildingList"
                          :key="item._id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                </div>
                <div class="form-actions mt-0">
                  <a-button
                    style="width: 150px"
                    type="primary"
                    htmlType="submit"
                    :loading="loadingUtilisateur"
                    :disabled="loadingUtilisateur"
                    class="mr-3"
                  >
                    {{ $t("action.ajouter") }}
                  </a-button>
                  <a-button
                    key="back"
                    @click="handleCancel"
                    :disabled="loadingUtilisateur"
                  >
                    {{ $t("action.annuler") }}
                  </a-button>
                </div>
              </a-form>
            </a-modal>
            <a-modal
              v-model="realVisibleModalPassword"
              @cancel="
                () => {
                  realVisibleModalPassword = false;
                }
              "
              :title="$t('utilisateur.changerMdp')"
              :width="750"
              :height="1200"
              :footer="false"
            >
              <a-form
                :form="formPassword"
                class="container"
                @submit="updatePassword"
              >
                <div class="row">
                  <div class="col-lg-6">
                    <a-form-item :label="$t('profil.mdp')">
                      <a-input
                        :placeholder="$t('profil.mdp')"
                        type="password"
                        v-decorator="[
                          'password',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.mdp'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-lg-6">
                    <a-form-item :label="$t('utilisateur.confirmerMdp')">
                      <a-input
                        :placeholder="$t('utilisateur.confirmerMdp')"
                        type="password"
                        v-decorator="[
                          'confirmPassword',
                          {
                            rules: [
                              {
                                required: true,
                                message: $t('requis.confirmation'),
                              },
                            ],
                          },
                        ]"
                      />
                    </a-form-item>
                  </div>
                </div>
                <vue-qr
                  :text="qrData"
                  logoSrc="/favicon.png"
                  :callback="getQrImage"
                  qid="teacher"
                ></vue-qr>
                <div class="form-actions mt-0">
                  <a-button
                    style="width: 150px"
                    type="primary"
                    htmlType="submit"
                    :loading="loadingUtilisateur"
                    class="mr-3"
                  >
                    {{ $t("action.modifier") }}
                  </a-button>
                  <a-button
                    key="back"
                    @click="
                      () => {
                        realVisibleModalPassword = false;
                      }
                    "
                    :disabled="loadingUtilisateur"
                  >
                    {{ $t("action.annuler") }}
                  </a-button>
                </div>
              </a-form>
            </a-modal>
          </div>
        </div>
        <div>
          <a-modal
            v-model="visibleModalPassword"
            @cancel="
              () => {
                visibleModalPassword = false;
              }
            "
            :title="$t('all.enterInformationBelow')"
            :width="750"
            :height="1200"
            :footer="false"
          >
            <a-form :form="formChange" class="container" @submit="updateUser">
              <div class="row">
                <div class="col-lg-6">
                  <a-form-item :label="$t('utilisateur.role')">
                    <a-select
                      show-search
                      :autoFocus="true"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      :placeholder="$t('utilisateur.role')"
                      v-decorator="[
                        'role',
                        {
                          initialValue: updateData.role,
                          rules: [
                            {
                              required: true,
                              message: $t('requis.role'),
                            },
                          ],
                        },
                      ]"
                    >
                      <a-select-option
                        :value="item._id"
                        v-for="item in roleList"
                        :key="item._id"
                      >
                        {{ item.role }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
                <div class="col-lg-6">
                  <a-form-item :label="$t('profil.nomUser')">
                    <a-input
                      :placeholder="$t('profil.nomUser')"
                      v-decorator="[
                        'userName',
                        {
                          initialValue: updateData.userName,
                          rules: [
                            {
                              required: true,
                              message: $t('requis.username'),
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <a-form-item :label="$t('utilisateur.acces')">
                    <a-select
                      show-search
                      :autoFocus="true"
                      :allowClear="true"
                      mode="multiple"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      style="width: 100%"
                      :placeholder="$t('utilisateur.acces')"
                      v-decorator="[
                        'buildings',
                        {
                          initialValue: updateData.buildings,
                          rules: [
                            {
                              required: true,
                              message: $t('requis.role'),
                            },
                          ],
                        },
                      ]"
                    >
                      <a-select-option
                        :value="item._id"
                        v-for="item in buildingList"
                        :key="item._id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </div>
              <div class="form-actions mt-0">
                <a-button
                  style="width: 150px"
                  type="primary"
                  htmlType="submit"
                  :loading="loadingUtilisateur"
                  class="mr-3"
                >
                  {{ $t("action.modifier") }}
                </a-button>
                <a-button
                  key="back"
                  @click="
                    () => {
                      visibleModalPassword = false;
                    }
                  "
                  :disabled="loadingUtilisateur"
                >
                  {{ $t("action.annuler") }}
                </a-button>
              </div>
            </a-form>
          </a-modal>
        </div>
      </template>
      <a-table
        :data-source="data"
        :columns="columns"
        rowKey="_id"
        :scroll="{ x: 'max-content' }"
        :loading="tableLoading"
      >
        <a-button
          slot="operation0"
          type="primary"
          slot-scope="text, record"
          @click="() => updatePasswordModal(record)"
        >
          <a-icon type="lock" /> {{ $t("action.changer") }}
        </a-button>
        <a-button
          slot="operation"
          slot-scope="text, record"
          @click="() => updateModal(record)"
        >
          <a-icon type="select" /> {{ $t("action.modifier") }}
        </a-button>
        <template slot="buildings" slot-scope="text, record">
          <div
            v-for="(building, index) in record.buildings"
            :key="building._id"
            class="d-inline"
          >
            <a-tag color="#2db7f5"> {{ building.name }}</a-tag>
            <div v-if="index % 2 == 1"><br /></div>
          </div>
        </template>
        <template slot="status" slot-scope="text">
          <div v-if="text == 'active'">
            <a-tag color="#87d068">{{ $t("utilisateur.active") }}</a-tag>
          </div>
          <div v-else>
            <a-tag color="#f50">{{ $t("utilisateur.nonActive") }}</a-tag>
          </div>
        </template>
        <template slot="operation2" slot-scope="text, record">
          <div v-if="user.userName != record.userName">
            <a-popconfirm
              v-if="record.status == 'active'"
              :title="$t('utilisateur.desactiverAlert')"
              @confirm="() => onDelete(record)"
            >
              <a-button type="danger">
                <a-icon type="delete" href="javascript:;" />{{
                  $t("action.desactiver")
                }}
              </a-button>
            </a-popconfirm>
            <a-popconfirm
              v-else
              :title="$t('utilisateur.activerAlert')"
              @confirm="() => onActivate(record)"
            >
              <a-button type="primary">
                <a-icon type="user" href="javascript:;" />{{
                  $t("action.activer")
                }}
              </a-button>
            </a-popconfirm>
          </div>
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import VueQr from "vue-qr";
import JsPDF from "jspdf";
import axios from "axios";

const { AwesomeQR } = require("awesome-qr");

export default {
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "userForm" });
  },
  components: { VueQr },
  computed: mapState(["user", "settings"]),
  created() {
    this.tableLoading = true;
    apiClient
      .post("/roles/filter", {
        query: {
          status: "active",
          $or: [
            {
              building: this.settings.activeBuildingId,
            },
            {
              role: "admin",
            },
          ],
        },
      })
      .then((res) => (this.roleList = res.data))
      .catch((e) => this.$message.error(this.$t("error.aucRole")));
    let buidlingList = [];
    this.user.building.forEach((currentItem) => {
      buidlingList.push(currentItem._id);
    });
    apiClient
      .post("/buildings/filter", {
        query: {
          _id: buidlingList,
          active: true,
          "access.edManager.active": true,
        },
      })
      .then((res) => (this.buildingList = res.data))
      .catch((e) => {
        console.error(e);
        this.$message.error(this.$t("error.aucEtablissement"));
      });
    apiClient
      .get("/autorisation/buildingUsers")
      .then((res) => {
        this.allUsers = res.data;

        this.data = this.allUsers.filter((e) => e.status === "active");
        this.tableLoading = false;
      })
      .catch((e) => {
        console.log(e);
        this.$message.error(this.$t("error.aucUser"));
        this.tableLoading = false;
      });
  },
  data() {
    // this.cacheData = data.map((item) => ({ ...item }));
    return {
      qrDataImage: "",
      qrData: "",
      checkedActiveUser: true,
      allUsers: [],
      formChange: this.$form.createForm(this),
      tableLoading: false,
      roleList: [],
      buildingList: [],
      editingKey: "",
      data: null,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      userLogin: null,
      loadingUtilisateur: false,
      form: this.$form.createForm(this),
      visibleModal: false,
      updateData: { buildings: [], role: "", userName: "", _id: "" },
      visibleModalPassword: false,
      realVisibleModalPassword: false,
      formPassword: this.$form.createForm(this),
      columns: [
        {
          title: this.$t("profil.nomUser"),
          dataIndex: "userName",
          key: "userName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "userName",
          },
          onFilter: (value, record) =>
            record.userName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("utilisateur.role"),
          dataIndex: "role.role",
          key: "role",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "role",
          },
          onFilter: (value, record) =>
            record.role.role
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("utilisateur.acces"),
          dataIndex: "buildings",
          key: "buildings",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "buildings",
          },
          onFilter: (value, record) => {
            for (let i = 0; i < record.buildings.length; i++) {
              if (
                record.buildings[i].name
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              ) {
                return true;
              }
            }
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("topBar.status"),
          dataIndex: "status",
          key: "status",
          scopedSlots: {
            customRender: "status",
          },
          filters: [
            { text: this.$t("utilisateur.active"), value: "active" },
            { text: this.$t("utilisateur.nonActive"), value: "inactive" },
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
        {
          title: this.$t("utilisateur.mdp"),
          scopedSlots: { customRender: "operation0" },
          fixed: "right",
        },
        {
          title: this.$t("profil.gere"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("utilisateur.activerDesactiver"),
          dataIndex: "operation2",
          scopedSlots: { customRender: "operation2" },
          fixed: "right",
        },
      ],
    };
  },
  methods: {
    validateNoWhitespace(rule, value, callback) {
      if (/\s/.test(value)) {
        callback(new Error("Username cannot contain whitespace"));
      } else {
        callback();
      }
    },
    getQrImage(dataUrl) {
      this.qrDataImage = dataUrl;
    },
    updatePasswordModal(record) {
      this.updateData = {
        buildings: [],
        role: record.role._id,
        userName: record.userName,
        _id: record._id,
      };

      this.qrData = btoa(record._id + "." + this.settings.base_url);
      this.realVisibleModalPassword = true;
    },

    updateModal(record) {
      this.updateData = {
        buildings: record.buildings.map((e) => e._id),
        role: this.roleList.some(function (el) {
          return el._id === record.role._id;
        })
          ? record.role._id
          : undefined,
        userName: record.userName,
        _id: record._id,
      };
      this.visibleModalPassword = true;
    },
    updatePassword(e) {
      e.preventDefault();
      this.formPassword.validateFieldsAndScroll((err, values) => {
        this.loadingUtilisateur = true;

        if (values.password != values.confirmPassword) {
          this.$message.error(this.error("error.verifier"));
          this.loadingUtilisateur = false;
        } else {
          apiClient
            .patch("/users", {
              userName: this.updateData.userName,
              updates: {
                password: values.password,
              },
            })
            .then(() => {
              this.$message.success(this.$t("success.utilisateurMSA"));
              this.$socket.client.emit("updateuser", {
                userName: this.updateData.userName,
              });
              this.generatePdf(this.updateData, values.password);
              this.$emit("reload");
            })
            .catch((e) => {
              console.error(e);
              this.$message.error(this.$t("error.modif"));
            })
            .finally(() => (this.loadingUtilisateur = false));
        }
      });
    },
    updateUser(e) {
      this.loadingUtilisateur = true;
      e.preventDefault();
      this.formChange.validateFieldsAndScroll((err, values) => {
        if (values.role) {
          apiClient
            .patch("/users", {
              userName: this.updateData.userName,
              updates: {
                userName: values.userName,
                role: values.role,
                buildings: values.buildings,
              },
            })
            .then(() => {
              this.$message.success(this.$t("success.utilisateurMSA"));
              console.log(this.updateData.userName);
              this.$socket.client.emit("updateuser", {
                userName: this.updateData.userName,
              });

              this.$emit("reload");
            })
            .catch(() => {
              this.$message.error(this.$t("error.modif"));
            })
            .finally(() => (this.loadingUtilisateur = false));
        } else {
          this.$message.error(this.$t("requis.role"));
        }
      });
    },
    moment(any) {
      return moment(any);
    },
    onChange(checked) {
      console.log(`a-switch to ${checked}`);
      this.data = this.allUsers.filter(
        (e) => e.status === (checked == true ? "active" : "inactive")
      );
      this.tableLoading = false;
    },
    showModal() {
      this.visibleModal = true;
    },
    addUtilisateur(e) {
      this.loadingUtilisateur = true;
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (values.password != values.confirmPassword) {
          this.$message.error(this.$t("error.verifier"));
          this.loadingUtilisateur = false;
        } else {
          apiClient
            .put("/users", {
              userName: values.userName,
              password: values.password,
              role: values.role,
              buildings: values.buildings,
              status: "active",
            })
            .then((res) => {
              this.qrData = btoa(res._id + "." + this.settings.base_url);
              this.generatePdf(
                { userName: values.userName },
                values.password,
                true
              );
              this.$emit("reload");
              this.$message.success(this.$t("success.utilisateurAjout"));
              this.handleCancel();
            })
            .catch((e) => {
              console.log(e);
              this.$message.warning(this.$t("error.utilisateurNonAjoutDiff"));
            })
            .finally(() => (this.loadingUtilisateur = false));
        }
      });
    },
    handleCancel(e) {
      this.visibleModal = false;
      this.visibleModalPassword = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = "";
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    onDelete(record) {
      apiClient
        .patch("/users", {
          userName: record.userName,
          updates: {
            status: "inactive",
          },
        })
        .then((r) => {
          this.data = this.data.map((elem) =>
            elem._id == record._id ? { ...elem, status: "inactive" } : elem
          );
          this.$socket.client.emit("desactivation", {
            userName: record.userName,
          });
          this.$message.success(this.$t("utilisateur.userDesactive"));
        })
        .catch(() => this.$$message.error(this.$t("error.modif")));
    },
    onActivate(record) {
      apiClient
        .patch("/users", {
          userName: record.userName,
          updates: {
            status: "active",
          },
        })
        .then((r) => {
          this.data = this.data.map((elem) =>
            elem._id == record._id ? { ...elem, status: "active" } : elem
          );
          this.$message.success(this.$t("utilisateur.userActive"));
        })
        .catch(() => this.$$message.error(this.$t("error.modif")));
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    //Code profil selection functions
    filterOptionCodeProfil(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    filterOptionFilter(inputValue, option) {
      return option.description.indexOf(inputValue) > -1;
    },
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },

    async generatePdf(record, password, generateQr = false) {
      this.$gtag.event("Imp information EdManager login", {
        event_category: "Impression PDF",
        event_label: "Administration - Utilisateur section",
        value: 1,
      });

      const arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      const date = new Date();
      const titre = "EdManager : Informations de connexion";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(65, 70, titre);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(15);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      doc.setFontSize(12);
      doc.text(40, 100, "Nom d'utilisateur : " + record.userName);
      doc.text(40, 120, "Mot de passe : " + password);
      doc.text(
        40,
        140,
        "Vous pouvez s'identifier en utlisant ce Qr code dans l'application mobile :"
      );

      if (generateQr) {
        let logo = "data:image/jpeg;base64, ";

        await axios
          .get("favicon.png", {
            responseType: "arraybuffer",
          })
          .then((response) => {
            logo += Buffer.from(response.data, "binary").toString("base64");
          });

        await new AwesomeQR({
          text: this.qrData,
          logoImage: logo,
          size: 200,
          correctLevel: 1,
          logoScale: 0.2,
          logoMargin: 0,
          logoCornerRadius: 8,
          whiteMargin: true,
          dotScale: 1,
          autoColor: true,
          binarize: false,
          binarizeThreshold: 128,
          bindElement: true,
          backgroundColor: "#FFFFFF",
        })
          .draw()
          .then((dataURL) => {
            doc.addImage(dataURL, "JPEG", 80, 150, 45, 45);
          });
      } else {
        doc.addImage(this.qrDataImage, "JPEG", 80, 150, 45, 45);
      }

      //end page numbering
      doc.save(record.userName + ".pdf");
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
</style>
