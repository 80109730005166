<template>
  <user :key="count" v-on:reload="reload"></user>
</template>
<script>
/* eslint-disable */
import user from "./user";
export default {
  components: { user },
  data() {
    return { count: 0 };
  },
  methods: {
    reload() {
      this.count = this.count + 1;
    },
  },
};
</script>